import React, { useContext, createContext, FC, useState } from 'react'
import { Console } from '../debug/Console'
import { authType } from '../pages/Auth/Auth'
import { AuthService } from '../services/AuthService'

type authState = {
  user?: string
  isAuthorized: boolean
  setIsAuthorized: () => void
  authRequest: (cred: authType) => void
  logoutRequest: () => void
}

const initialState: authState = {
  user: '',
  // isAuthorized: false,
  isAuthorized: !!AuthService().getUser(),
  setIsAuthorized: () => undefined,
  authRequest: () => undefined,
  logoutRequest: () => undefined,
}

const AuthContext = createContext<authState>(initialState)

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider: FC = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(initialState.isAuthorized)
  console.log(
    '🚀 ~ file: AuthProvider.tsx ~ line 29 ~ isAuthorized',
    isAuthorized
  )

  const authRequest = async (creds: authType) => {
    try {
      const res: any = await AuthService().authRequest(creds)
      AuthService().setUser(res.token)
      setIsAuthorized(true)
    } catch (err) {
      AuthService().setUser('')
      //@ts-ignore
      throw err
    }
  }

  const logoutRequest = async () => {
    try {
      const res = await AuthService().logoutRequest()
      Console.success(res)
      setIsAuthorized(false)
      AuthService().setUser('')
    } catch (err) {
      Console.danger('YOU LOSE')
      throw err
    }
  }

  return (
    <AuthContext.Provider
      // @ts-ignore
      value={{ isAuthorized, setIsAuthorized, authRequest, logoutRequest }}
    >
      {children}
    </AuthContext.Provider>
  )
}
