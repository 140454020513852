import { FC, useState } from 'react'
import { SidebarList } from './SidebarList/SidebarList'
import './Sidebar.scss'
import BurgerIcon from '../../assets/img/burger.svg'
import classNames from 'classnames'

export const Sidebar: FC = () => {
  const [minSidebar, setMinSidebar] = useState(false)

  function handleSidebarType() {
    setMinSidebar(!minSidebar)
  }

  const extraClass = minSidebar ? 'sidebar_min' : ''

  const arrowWrapperCx = classNames({
    'sidebar__min-max': true,
    'sidebar__min-max--center': minSidebar,
  })

  const arrowCx = classNames({
    'sidebar__min-max__img': true,
    'sidebar__min-max__img--close': !minSidebar,
  })

  return (
    <div className={`sidebar ${extraClass}`}>
      <div className={arrowWrapperCx} onClick={handleSidebarType}>
        <img className={arrowCx} src={BurgerIcon} alt="arrow" />
      </div>
      <SidebarList minSidebar={minSidebar} />
    </div>
  )
}
