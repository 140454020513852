import * as Yup from 'yup'

const needed = 'Обов’язкове поле'

const validPhone = Yup.string()
  .required(needed)
  .matches(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    'Не вірно введений номер телефону'
  )

export let companyCardValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required(needed),
  edrpo: Yup.string()
    .required(needed)
    .matches(/^[0-9]+$/, 'Має містити лише цифри')
    .min(8, 'ЄДРПОУ складається з 8 цифр')
    .max(8, 'ЄДРПОУ складається з 8 цифр'),
  iban: Yup.string()
    .required(needed)
    .matches(
      /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/,
      'Не відповідає формату IBAN'
    )
    .min(15, 'Мінімальна довжина IBAN сягає 15 символів')
    .max(32, 'Максимальна довжина IBAN сягає 32 символи'),
  bankName: Yup.string().required(needed),
  ipn: Yup.string()
    .required(needed)
    .matches(/^[0-9]+$/, 'Має містити лише цифри')
    .min(10, 'IPN складається з 10 цифр')
    .max(10, 'IPN складається з 10 цифр'),

  pdv: Yup.string().required(needed),
  contractNumber: Yup.string().trim().required(needed),
  // contractDate: Yup.date().typeError('Має бути записано у форматі dd.mm.yyyy'),
  contractDate: Yup.string().required('Має бути записано у форматі'),

  companyAddress: Yup.string().required(needed),
  companyAddressPhysically: Yup.string().required(needed),
  // companyPhone: validPhone,
  // companyEmail: Yup.string().required(needed).email('Це не email'),
  // contacts: Yup.object({
  //   phone: validPhone,
  //   email: Yup.string().required(needed).email('Це не email'),
  // }),
  personResponsibleForSigning: Yup.object({
    name: Yup.string().required(needed),
    phone: validPhone,
    email: Yup.string().required(needed).email('Це не email'),
  }),
  personResponsibleForConnectionAndPaymant: Yup.object({
    name: Yup.string().required(needed),
    phone: Yup.string()
      .required(needed)
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        'Не вірно введений номер телефону'
      ),
    email: Yup.string().required(needed).email('Це не email'),
  }),
})

export let companyDepartmentValidationSchema = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().trim().required(needed),
    address: Yup.string().required(needed),
    latitude: Yup.string().required(needed),
    longitude: Yup.string().required(needed),
    allowRadius: Yup.string().required(needed),
  })
)

export let companyLicenseValidationSchema = Yup.array().of(
  Yup.object().shape({
    department_id: Yup.number().not([0], needed),
    phone: validPhone,
    email: Yup.string().required(needed),
    in_charge_person: Yup.string().required(needed),
  })
)
