import { useFormik } from 'formik'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  selectCompanyTabs,
  selectSelectedCompany,
  setEditTabs,
  setSelectedCompany,
} from '../../../slice/companySlice'
import { CompanyHeader } from './CompanyHeader'
import styles from '../styles/Accordion.module.scss'
import UtilsStyles from '../styles/Utils.module.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import { FormField } from './FormField'
import ArrowUp from '../../../assets/img/arrowUp.svg'
import ArrowDown from '../../../assets/img/arrowDown.svg'
import trashCan from '../../../assets/img/trashCan.svg'
import { useHistory } from 'react-router'
import { LicenseService } from '../../../services/LicenseService'
import { CompanyService } from '../../../services/CompanyService'
import { Loader } from '../../../components/Loader/Loader'
import { v4 as uuidv4 } from 'uuid'
import { companyLicenseValidationSchema } from '../helpers/CompanyCardValidation'
import { License } from '../../../types/License'

type Props = {
  shouldEdit?: boolean
  title: string
}

export type employeeType = {
  id: number | string
  departments: string
  phone: string
  email: string
  in_charge_person: string
  department_id: string
  user_id?: number
  created_at: string
  license?: License[] & { deleted_at: string }[]
}

export type employeeResponseType = {
  id: number
  department: string
  email: string
  in_charge_person: string
  department_id: string
  user_id: number
  created_at: string
  user: {
    login: string
  }
  license: License[]
}

export const CompanyLicenceCard: FC<Props> = () => {
  const selectActiveTab = useAppSelector(selectCompanyTabs)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const selectedCompany = useAppSelector(selectSelectedCompany)

  const [loading, setLoading] = useState(false)

  const selectedItem = selectActiveTab.find((tab) => tab.selected)
  const isEdit = selectedItem?.isEdit || false

  //@ts-ignore
  const { departments } = selectedCompany

  const employees = departments
    ?.map((department: any) => department.employees)
    .flat()

  // check if license(employee) have active license
  const prepareArray: employeeResponseType[] = employees?.filter(
    (el: any) => !!el.license?.length
  )
  //@ts-ignore
  const initialValues: employeeType[] = prepareArray.map(
    (el: employeeResponseType) => {
      return {
        id: el.id,
        departments: el.department,
        department_id: el.department_id,
        phone: el.user?.login,
        email: el.email,
        in_charge_person: el.in_charge_person,
        user_id: el.user_id,
        license: el.license,
        created_at: el.created_at,
      }
    }
  )

  const formik = useFormik({
    initialValues,
    validationSchema: companyLicenseValidationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      console.log(values)

      dispatch(
        setEditTabs({
          id: selectedItem!.id,
          value: false,
        })
      )

      const idsForFile: number[] = []

      try {
        setLoading(true)
        values.forEach(async (el: employeeType) => {
          const data = {
            userId: el.user_id,
            email: el.email,
            inChargePerson: el.in_charge_person,
            departmentId: el.department_id,
          }

          const res: any = await LicenseService().createEmployee(data)
          const { license: licenseId } = res
          console.log('licenseId', licenseId)
          idsForFile.push(await licenseId)
          let fileCreated = false
          const sendRequest = async () => {
            //@ts-ignore
            const res: { file: string } =
              await LicenseService().sendGeneratePdf(await licenseId)
            fileCreated = !!res.file
          }

          function loop() {
            setTimeout(async () => {
              await sendRequest()
              if (!fileCreated) {
                loop()
              } else {
                await updateCompany()
                setLoading(false)
              }
            }, 200)
          }

          loop()
        })
        console.log('ids for file', idsForFile)
        await updateCompany()
      } catch (e) {
        setLoading(false)
        console.log('error on license creation : ', e)
      }
    },
  })
  useEffect(() => {
    formik.setValues(initialValues)
  }, [selectedCompany])

  const { errors } = formik

  async function resetChanges() {
    let reset = true
    // eslint-disable-next-line no-restricted-globals
    if (formik.dirty) reset = await confirm('Ви впевнені?')
    if (await reset) {
      if (selectedItem) {
        dispatch(
          setEditTabs({
            id: 3,
            value: false,
          })
        )
        formik.setValues(initialValues)
      }
    }
  }

  function onEdit() {
    if (selectedItem) {
      dispatch(
        setEditTabs({
          id: selectedItem?.id,
          value: true,
        })
      )
    }
  }

  const [nothing, updateComponent] = useState(true)
  function addNew() {
    formik.values.unshift({
      id: uuidv4(),
      departments: '',
      phone: '',
      email: '',
      in_charge_person: '',
      department_id: '0',
      created_at: '',
    })
    updateComponent(!nothing)
  }

  const updateCompany = async () => {
    //@ts-ignore
    const { companyId } = history.location.state
    const company = await CompanyService().loadCompanyById(companyId)
    dispatch(setSelectedCompany(company))
    console.log('@@@ COMPANY UPDATED FROM LIC')
  }

  const annulLicense = async (employee: employeeType) => {
    console.log(
      '🚀 ~ file: CompanyLicenceCard.tsx ~ line 170 ~ annulLicense ~ license',
      employee
    )
    // eslint-disable-next-line no-restricted-globals
    const del = confirm('Ви впевнені?')
    if (del) {
      try {
        let toDelete = employee?.license?.find((el) => !el.deleted_at && el.id)
        console.log(
          '🚀 ~ file: CompanyLicenceCard.tsx ~ line 176 ~ annulLicense ~ toDelete',
          toDelete
        )
        if (toDelete === undefined) {
          //@ts-ignore
          toDelete = employee
          const newValues = formik.values.filter((el) => el.id != toDelete?.id)
          updateComponent(!nothing)
          formik.setValues(newValues)
        } else {
          await LicenseService().annulLicense(toDelete.id)
          await updateCompany()
        }
      } catch (e: any) {
        throw Error(e)
      }
    }
  }

  const RenderLicenses = (license: employeeType, someId = 0) => {
    const findOrCreateUser =
      //  useCallback(async
      async () => {
        let user: any = await LicenseService().getUserByLogin(
          formik.values[someId].phone
        )
        if (!user.length) {
          user = await LicenseService().storeNewUser(
            formik.values[someId].phone
          )
        }
        user = user[0] || user
        console.log('user', user)
        const { id: userId } = user
        formik.values[someId].user_id = userId
      }
    // , [formik.values[someId].phone])
    return (
      <AccordionItem key={license.id} uuid={license.id}>
        <AccordionItemHeading className={styles.accordion}>
          <AccordionItemButton className={styles.accordionBtn}>
            <div className={styles.accordionBtn_title}>
              <p className="body-text-3_b">Ліцензія</p>
              &nbsp;
              <p className="body-text-3_i">
                {!license.created_at ? 'нова' : license.id}
              </p>
            </div>
            {isEdit ? (
              <div className={styles.accordionBtn_title}>
                <img src={trashCan} alt="delete" />
                <p
                  className="body-text-3-b"
                  style={{ paddingRight: 20 }}
                  onClick={() => annulLicense(license)}
                >
                  Анулювати ліцензію
                </p>
                <AccordionItemState>
                  {({ expanded }) => (
                    <img alt="btn" src={expanded ? ArrowDown : ArrowUp} />
                  )}
                </AccordionItemState>
              </div>
            ) : (
              <AccordionItemState>
                {({ expanded }) => (
                  <img alt="btn" src={expanded ? ArrowDown : ArrowUp} />
                )}
              </AccordionItemState>
            )}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FormField
            isEdit={isEdit}
            error={errors[someId]?.department_id}
            formik={formik}
            valueKey={formik.values[someId].department_id}
            tag="select"
            title="Підрозділ"
            name={`${someId}.department_id`}
            options={departments}
          />
          <div className="info-card__flex-filed-wrapper">
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.phone}
              formik={formik}
              valueKey={formik.values[someId].phone}
              // textAreaStyle={{
              //   resize: isEdit ? 'vertical' : 'none',
              // }}
              tag="input"
              title="Номер телефону"
              name={`${someId}.phone`}
              onBlur={findOrCreateUser}
            />
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.email}
              formik={formik}
              valueKey={formik.values[someId].email}
              tag="input"
              title="Електронна адреса"
              name={`${someId}.email`}
            />
          </div>
          <div className="info-card__flex-filed-wrapper">
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.in_charge_person}
              formik={formik}
              valueKey={formik.values[someId].in_charge_person}
              tag="input"
              title="Відповідальна особа"
              name={`${someId}.in_charge_person`}
            />
            {!isEdit && (
              <FormField
                valueKey={formik.values[someId].phone}
                tag="download"
              />
            )}
          </div>
          <div className="line" />
        </AccordionItemPanel>
      </AccordionItem>
    )
  }
  return (
    <div className="shadow-container">
      {loading && <Loader />}
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        //@ts-ignore
        preExpanded={[history.location?.state?.licenseId]}
      >
        <CompanyHeader
          onCancel={resetChanges}
          onEdit={onEdit}
          onSave={formik.submitForm}
        />
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          className={UtilsStyles['form-container']}
        >
          <div className={styles.subHeader}>
            <div className={styles.subHeader__btn}>
              <p className="body-text-3_d">Ліцензій:&nbsp;</p>
              <p className="body-text-3_i"> {formik.values.length || 0}</p>
            </div>
            {isEdit && (
              <div className={styles.subHeader__btn}>
                <p className="body-text-3_i">Додати ліцензію</p>
                &nbsp;
                <button
                  type="button"
                  onClick={() => addNew()}
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: 'black',
                    border: 'none',
                  }}
                />
              </div>
            )}
          </div>
          {formik.values.map((el: employeeType, someId = 0) =>
            RenderLicenses(el, someId++)
          )}
        </form>
      </Accordion>
    </div>
  )
}
