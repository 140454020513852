import React, { FC, useState } from 'react'
import { useFormik } from 'formik'
import { FormField } from '../Companies/components/FormField'
import { Button } from '../../components/Button/Button'
import { useAuthContext } from '../../Provider/AuthProvider'
import { authValidation } from './AuthValidation'
import eye from '../../assets/img/eye.svg'

export type authType = {
  login: string
  password: string
}

export const AuthPage: FC = () => {
  const { authRequest } = useAuthContext()
  const [showPass, setShowPass] = useState(false)

  const initialValues: authType = {
    login: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: authValidation,
    validateOnChange: true,
    onSubmit: async (values) => {
      console.log('submitted form', values)
      try {
        await authRequest(values)
      } catch (e) {
        //@ts-ignore
        if (e.message.includes('password')) formik.errors.password = e.message
        //@ts-ignore
        else formik.errors.login = e.message
      }
    },
  })

  return (
    <div className="shadow-container auth">
      <h1 style={{ marginBottom: 0 }}>Вхід</h1>
      <p className="body-text-2_d">Будь ласка, авторизуйтесь</p>
      <form
        style={{ width: 330 }}
        onSubmit={formik.handleSubmit}
        // className={UtilsStyles['form-container']}
        method="post"
      >
        <FormField
          isEdit={true}
          error={formik.errors.login}
          formik={formik}
          valueKey={formik.values.login}
          tag="input"
          title="Логін"
          name="login"
          type="email"
        />
        <div>
          <FormField
            isEdit={true}
            error={formik.errors.password}
            formik={formik}
            valueKey={formik.values.password}
            tag="input"
            title="Пароль"
            name="password"
            type={showPass ? 'text' : 'password'}
          />
          <img
            style={{ position: 'relative', top: -47, left: 300 }}
            onClick={() => setShowPass(!showPass)}
            src={eye}
            alt="show password"
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button text="Увійти" onClick={formik.submitForm} />
        </div>
      </form>
    </div>
  )
}
