import classNames from 'classnames'
import { FC } from 'react'
import './Button.scss'

type Props = {
  onClick?: () => void
  text?: string
  leftIcon?: string
  leftIconTitle?: string
  disabled?: boolean
  btnType?: 'button' | 'submit' | 'reset'
  type?: 'danger' | 'success' | 'warn' | 'info' | 'black' | 'transparent'
  // color: 'red' | 'green' | 'yellow' | 'blue' | 'turquoise' | 'purple'
}

export const Button: FC<Props> = (props) => {
  const {
    onClick,
    text = '',
    leftIcon,
    leftIconTitle,
    type = 'info',
    disabled = false,
    children,
    btnType = 'submit',
  } = props

  const cx = classNames({
    button: true,
    'button--danger': type === 'danger',
    'button--success': type === 'success',
    'button--warn': type === 'warn',
    'button--info': type === 'info',
    'button--black': type === 'black',
    'button--transparent': type === 'transparent',
  })
  return (
    <button type={btnType} className={cx} onClick={onClick} disabled={disabled}>
      {children ? (
        children
      ) : (
        <>
          {leftIcon && <img src={leftIcon} alt={leftIconTitle} />}
          <p className="body-text-1">{text}</p>
        </>
      )}
    </button>
  )
}
