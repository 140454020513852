import axios, { AxiosResponse } from 'axios'
import { AuthService } from './AuthService'

type Props = {
  headers?: {}
}

export const RequestService = (props: Props) => {
  const { headers } = props

  const instance = axios.create({
    // baseURL: process.env.REACT_APP_BASE_URL,
    baseURL: 'https://dev.api.soc.business',
    headers: {
      Authorization: `Bearer ${AuthService().getUser()}`,
      Accept: 'application/json',
      'x-interpreter-app-type': 'connectoperator',
      'Content-Type': 'application/json',
      ...headers,
    },
  })

  const handleError = (error: any) => {
    console.log('SHEESH Err: ', error)
    let status = null
    let code = null
    let message = null

    if (error?.status === 200) {
      status = error.status
      code = error.code
      message = error.message
    } else {
      status = error.response?.status
      code = error.response?.data?.code
      message =
        error.response?.message ||
        error.response.data.message ||
        'Щось пiшло не так'
    }

    console.error(
      `Error in API - status: ${status}, code: ${code}, message ${message}`
    )

    if (status === 401) {
      // AuthService.signOut();
      return null
    }

    throw new Error(message)
    // return { status, code, message };
  }

  async function prepareResponse<T>(response: AxiosResponse<T>): Promise<T> {
    console.log('prepareResponse', response)
    // if (Math.round(response.status / 100) !== 2) {
    //   throw new Error(
    //     JSON.stringify({
    //       status: response.status,
    //       text: response.statusText,
    //     })
    //   )
    // }

    try {
      const res = await response
      console.log('response', res)
      //@ts-ignore
      return res
    } catch (err) {
      throw err
    }
  }

  // get request
  async function getRequest<T>(url: string) {
    return await prepareResponse<T>(await instance.get(url))
  }

  // post request
  async function postRequest(
    url: string,
    data: any,
    setProgress?: (c: any) => void
  ) {
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (setProgress) {
          setProgress(percentCompleted)
        }
      },
    }

    return await prepareResponse(await instance.post(url, data, config))
  }

  // put request
  async function putRequest(
    url: string,
    data: any,
    setProgress?: (c: any) => void
  ) {
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (setProgress) {
          setProgress(percentCompleted)
        }
      },
    }

    return await prepareResponse(await instance.put(url, data, config))
  }

  async function patchRequest(
    url: string,
    data: any,
    setProgress?: (c: any) => void
  ) {
    let config = {
      onUploadProgress: (progressEvent: any) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (setProgress) {
          setProgress(percentCompleted)
        }
      },
    }
    return await prepareResponse(await instance.patch(url, data, config))
  }

  // delete request
  async function deleteRequest(url: string, data: any) {
    return await prepareResponse(
      await instance.delete(url, {
        data,
      })
    )
  }

  instance.interceptors.response.use(
    (response) => {
      if (Math.round(response.status / 100) !== 2) {
        throw new Error(
          JSON.stringify({
            status: response.status,
            text: response.statusText,
          })
        )
      }

      try {
        console.log('interceptor response', response)
        response = response?.data
        return response
      } catch (err) {
        console.log('interceptor catch', response.status)
        return response
      }
    },
    (error) => {
      console.log('interceptor error', error)
      return handleError(error)
    }
  )

  return {
    instance,
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    patchRequest,
  }
}
