import React, { FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { routeNames } from '../../../constants/routeNames'
import { CompanyService } from '../../../services/CompanyService'
import { DepartmentService } from '../../../services/DepartmentService'
import { LicenseService } from '../../../services/LicenseService'
// import {
//   selectCompanyList,
//   selectDeparmentList,
//   selectLicneseList,
// } from '../../../slice/companySlice'

type Props = {}

export const CountInfo: FC<Props> = () => {
  // const compLength = useAppSelector(selectCompanyList)
  // const departLength = useAppSelector(selectDeparmentList)
  // const licLength = useAppSelector(selectLicneseList)

  const [count, setCount] = useState({
    companies: 0,
    deparments: 0,
    licenses: 0,
  })

  useEffect(() => {
    const count = async () => {
      //@ts-ignore
      const companiesLength: number = await //@ts-ignore
      CompanyService().loadCompanyList()

      const departmentsLength = await //@ts-ignore
      DepartmentService().loadDepartmentList()

      //@ts-ignore
      const licensesLength = await LicenseService().loadLicenseList()

      setCount({
        //@ts-ignore
        companies: await companiesLength.total,
        //@ts-ignore
        deparments: await departmentsLength.total,
        //@ts-ignore
        licenses: await licensesLength.total,
      })

      //@ts-ignore
      // return await
    }
    count()
  }, [])
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex' }}>
        <p className="body-text-2_d">{routeNames['/companies'].genitive}: </p>
        <p className="body-text-2">{count.companies}</p>
        {/* <p className="body-text-2">{compLength.length}</p> */}
        <p
          className="body-text-2_d"
          style={{
            marginRight: 5,
            marginLeft: 5,
          }}
        >
          {' '}
          |{' '}
        </p>
      </div>
      <div style={{ display: 'flex' }}>
        <p className="body-text-2_d">{routeNames['/departments'].genitive}: </p>
        <p className="body-text-2">{count.deparments}</p>
        {/* <p className="body-text-2">{departLength.length}</p> */}

        <p
          className="body-text-2_d"
          style={{
            marginRight: 5,
            marginLeft: 5,
          }}
        >
          {' '}
          |{' '}
        </p>
      </div>

      <div style={{ display: 'flex' }}>
        <p className="body-text-2_d">{routeNames['/licenses'].genitive}: </p>
        <p className="body-text-2">{count.licenses}</p>
        {/* <p className="body-text-2">{licLength.length}</p> */}
      </div>
    </div>
  )
}
