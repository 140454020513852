import './SidebarList.scss'
import DashboardIcon from '../../../assets/img/home.svg'
import CompanyIcon from '../../../assets/img/company.svg'
import DepartmentsIcon from '../../../assets/img/departments.svg'
import LicenceIcon from '../../../assets/img/licence.svg'
import { SidebarItem } from '../SidebarItem/SidebarItem'
import { SidebarItemType } from '../types'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button } from '../../Button/Button'
import { useAuthContext } from '../../../Provider/AuthProvider'
import { Accordion } from 'react-accessible-accordion'
import { routeNames } from '../../../constants/routeNames'

const list: SidebarItemType[] = [
  {
    id: 0,
    title: routeNames['/companies'].title,
    subTitle: 'Всі компанії',
    createTitle: routeNames['/companies'].createTitle,
    createPath: routeNames['/companies'].create,
    img: {
      path: CompanyIcon,
      alt: 'garbage can icon',
    },
    path: '/companies/page=1',
    selectable: false,
  },
  {
    id: 1,
    title: routeNames['/departments'].title,
    subTitle: 'Всі підрозділи',
    createTitle: routeNames['/departments'].createTitle,
    createPath: routeNames['/departments'].create,
    img: {
      path: DepartmentsIcon,
      alt: 'Departments icon',
    },
    path: '/departments/page=1',
    selectable: false,
  },
  {
    id: 2,
    title: routeNames['/licenses'].title,
    subTitle: 'Всі ліцензії',
    createTitle: routeNames['/licenses'].createTitle,
    createPath: routeNames['/licenses'].create,
    img: {
      path: LicenceIcon,
      alt: 'person icon',
    },
    path: '/licenses/page=1',
    selectable: false,
  },
]

type Props = { minSidebar: boolean }

export const SidebarList: FC<Props> = ({ minSidebar = false }) => {
  const { logoutRequest } = useAuthContext()
  const history = useHistory()
  const [pageList, setPageList] = useState(
    list.map((item) => ({
      ...item,
      selectable: history.location.pathname === item.path,
    }))
  )

  useEffect(() => {
    history.listen(({ pathname }) => {
      setPageList(
        list.map((item) => ({
          ...item,
          selectable: pathname === item.path,
        }))
      )
    })
  }, [history])

  return (
    <div className="sidebar-list">
      <p className="body-text-3_d" style={{ marginLeft: 24 }}>
        {!minSidebar ? 'АДМІНІСТРУВАННЯ' : ''}
      </p>
      <Accordion
      //@ts-ignore
      // preExpanded={[]}
      // allowMultipleExpanded
      // allowZeroExpanded
      >
        {pageList.map((item) => (
          <SidebarItem
            item={item}
            key={item.id}
            selectable={item.selectable}
            minSidebar={minSidebar}
          />
        ))}
        {!minSidebar && (
          <div
            style={{
              marginTop: 50,
              marginLeft: 15,
              display: 'flex',
              alignSelf: 'center',
              alignItems: 'center',
              // backgroundColor: 'white',
              // alignContent: 'center',
              // justifyContent: 'center',
            }}
          >
            <Button text="Вийти" onClick={logoutRequest} type="transparent" />
          </div>
        )}
      </Accordion>
    </div>
  )
}
