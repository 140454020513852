import React, { useEffect, useState } from 'react'
import queryString from 'query-string'

import { useHistory, useParams } from 'react-router'
import styles from '../styles/PageBtns.module.scss'
import Arrow from '../../../assets/img/arrow.svg'
import { DepartmentService } from '../../../services/DepartmentService'
import { useAppDispatch } from '../../../app/hooks'
import {
  setCompanyList,
  setDepartmentList,
  setLicenseList,
} from '../../../slice/companySlice'
import { routeNames } from '../../../constants/routeNames'
import { CompanyService } from '../../../services/CompanyService'
import { LicenseService } from '../../../services/LicenseService'

type pageStateType = {
  current_page: number | null
  from: number | null
  to: number | null
  total: number | null
  next_page: number | null
  prev_page: number | null
}

export const PageBtns = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const routeParams: {
    page: string
  } = useParams()

  const [pageState, setPageState] = useState<pageStateType>({
    current_page: null,
    from: null,
    to: null,
    total: null,
    prev_page: null,
    next_page: null,
  })

  const pageNumber = Number(routeParams?.page)

  const { pathname, search: searchQuery } = history.location
  // instead may be used react-router v6(useQueryParameters)
  const query = queryString.parse(searchQuery)
  const { search } = query

  function handleNextPage() {
    let nextPage = pageNumber + 1 || 1
    history.push({
      pathname: `page=${nextPage}`,
      search: search ? `?search=${search}` : '',
    })
  }

  function handlePreviousPage() {
    let prevPage = pageNumber - 1 || 1
    history.push({
      pathname: `page=${prevPage}`,
      search: search ? `?search=${search}` : '',
    })
  }

  useEffect(() => {
    loadData()
    async function loadData() {
      let res: any = {}
      if (pathname.includes(routeNames['/companies'].route)) {
        if (!!query.search) {
          //@ts-ignore
          res = await CompanyService().findCompany(routeParams?.page, search)
        } else res = await CompanyService().loadCompanyList(routeParams?.page)
      }
      if (pathname.includes(routeNames['/departments'].route)) {
        if (!!query.search) {
          res = await DepartmentService().findDepartments(
            routeParams?.page,
            //@ts-ignore
            search
          )
        } else
          res = await DepartmentService().loadDepartmentList(routeParams?.page)
      }
      if (pathname.includes(routeNames['/licenses'].route)) {
        if (!!query.search) {
          //@ts-ignore
          res = await LicenseService().findLicenses(routeParams?.page, search)
        } else res = await LicenseService().loadLicenseList(routeParams?.page)
      }

      await res

      setPageState({
        to: res?.to,
        total: res?.total,
        current_page: res?.current_page,
        from: res?.from,
        next_page: res?.next_page,
        prev_page: res?.prev_page,
      })
      pathname.includes(routeNames['/companies'].route)
        ? dispatch(setCompanyList(res?.data))
        : pathname.includes(routeNames['/departments'].route)
        ? dispatch(setDepartmentList(res?.data))
        : dispatch(setLicenseList(res?.data))
    }
  }, [pageNumber, searchQuery])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <p className="body-text-3_j">
        {pageState.from}-{pageState.to} з {pageState.total}
      </p>
      <button
        className={styles['arrowWrapper-left']}
        onClick={handlePreviousPage}
        disabled={!pageState.prev_page}
      >
        <img alt="arrow" src={Arrow} />
      </button>
      <button
        className={styles['arrowWrapper']}
        onClick={handleNextPage}
        disabled={!pageState.next_page}
      >
        <img alt="arrow" src={Arrow} />
      </button>
    </div>
  )
}
