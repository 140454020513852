import * as Yup from 'yup'

const needed = 'Обов’язкове поле'

export let authValidation = Yup.object().shape({
  login: Yup.string().required(needed).email('Це не email'),
  password: Yup.string()
    .required(needed)
    .min(8, 'Мінімальна довжина 8 символів')
    .max(20, 'Максимальна довжина 20 символів'),
})
