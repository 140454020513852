import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

import {
  selectCompanyTabs,
  selectSelectedCompany,
  setEditTabs,
  setSelectedCompany,
} from '../../../slice/companySlice'
import { Department } from '../../../types/Department'
import { CompanyHeader } from './CompanyHeader'
import UtilsStyles from '../styles/Utils.module.scss'
import { FormField } from './FormField'
import styles from '../styles/Accordion.module.scss'
import ArrowUp from '../../../assets/img/arrowUp.svg'
import ArrowDown from '../../../assets/img/arrowDown.svg'
import { DepartmentService } from '../../../services/DepartmentService'
import { companyDepartmentValidationSchema } from '../helpers/CompanyCardValidation'
import { useHistory } from 'react-router'
import trashCan from '../../../assets/img/trashCan.svg'
import { CompanyService } from '../../../services/CompanyService'
import { v4 as uuidv4 } from 'uuid'
import { Button } from '../../../components/Button/Button'
import walletIcon from '../../../assets/img/wallet.svg'

type Props = {
  shouldEdit?: boolean
  title: string
}

export type DepartmentInitialType = {
  id: number
  name: string
  address: string
  companyId: string
  contacts: string
  latitude: string
  longitude: string
  allowRadius: string
  sendPost: boolean
  balance: {
    left: string
  }
}

export const CompanyDepartmentCard: FC<Props> = () => {
  const selectActiveTab = useAppSelector(selectCompanyTabs)
  const dispatch = useAppDispatch()
  const selectedCompany = useAppSelector(selectSelectedCompany)
  //@ts-ignore
  const { departments } = selectedCompany
  const licenses = departments
    ?.map((department: any) =>
      department.employees.filter(
        (employee: any) => !!employee.license.length && employee
      )
    )
    .flat()
  const selectedItem = selectActiveTab.find((tab) => tab.selected)
  const isEdit = selectedItem?.isEdit || false

  const history = useHistory()
  const initialValues: DepartmentInitialType[] =
    departments?.map((el: Department) => {
      // console.log('el', el)
      return {
        id: el.id,
        name: el.name || '',
        address: el.address || '',
        companyId: '',
        contacts: '',
        latitude: el.latitude || '',
        longitude: el.longitude || '',
        allowRadius: el.allow_radius || '',
        balance: {
          left: '0',
        },
      }
    }) || []
  let formik = useFormik({
    initialValues,
    validationSchema: companyDepartmentValidationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      dispatch(
        setEditTabs({
          id: selectedItem!.id,
          value: false,
        })
      )

      values.forEach(async (el: DepartmentInitialType) => {
        const data = new FormData()
        //@ts-ignore
        const formated = el.sendPost
          ? {
              //@ts-ignore
              companyId: selectedCompany.id,
              name: el.name,
              latitude: el.latitude.toString(),
              longitude: el.longitude.toString(),
              allow_radius: el.allowRadius.toString(),
              contacts: [{ phone: '24313123123', email: '131231231@asd.com' }],
              address: el.address,
            }
          : {
              departmentId: el.id,
              companyId: +el.companyId,
              name: el.name,
              latitude: el.latitude,
              longitude: el.longitude,
              allow_radius: el.allowRadius,
              address: el.address,
              contacts: [{ phone: '24313123123', email: '131231231@asd.com' }],
            }

        for (var key in formated) {
          // @ts-ignore
          data.append(key, formated[key])
          //@ts-ignore
          console.log(key, formated[key])
        }
        el.sendPost
          ? await DepartmentService().createDepartment(formated)
          : //@ts-ignore
            await DepartmentService().updateDepartment(formated)
      })
      await updateCompany()
    },
  })
  useEffect(() => {
    formik.setValues(initialValues)
  }, [selectedCompany])
  const { errors } = formik

  async function resetChanges() {
    let reset = true
    // eslint-disable-next-line no-restricted-globals
    if (formik.dirty) reset = await confirm('Ви впевнені?')
    if (await reset) {
      if (selectedItem) {
        dispatch(
          setEditTabs({
            id: 2,
            value: false,
          })
        )
        formik.setValues(initialValues)
      }
    }
  }

  function onEdit() {
    if (selectedItem) {
      dispatch(
        setEditTabs({
          id: selectedItem?.id,
          value: true,
        })
      )
    }
  }
  const [nothing, updateComponent] = useState(true)
  function addNew() {
    formik.values.unshift({
      //@ts-ignore
      id: uuidv4(),
      name: '',
      address: '',
      companyId: '',
      contacts: '',
      latitude: '',
      longitude: '',
      allowRadius: '',
      sendPost: true,
    })
    updateComponent(!nothing)
  }

  const updateCompany = async () => {
    //@ts-ignore
    const { companyId } = history.location.state
    const company = await CompanyService().loadCompanyById(companyId)
    dispatch(setSelectedCompany(company))
    console.log('@@@ update from deps')
  }

  const deleteDepartment = async (department: DepartmentInitialType) => {
    // eslint-disable-next-line no-restricted-globals
    const del = confirm('Ви впевнені?')
    if (del) {
      try {
        if (department.sendPost) {
          const newValues = formik.values.filter((el) => el.id != department.id)
          updateComponent(!nothing)
          formik.setValues(newValues)
        } else {
          await DepartmentService().deleteDepartment(department.id)
          await updateCompany()
        }
        //@ts-ignore
      } catch (e: Error) {
        throw Error(e)
      }
    }
  }

  const replenish = async (department: DepartmentInitialType, left: number) => {
    try {
      const response = await DepartmentService().replenishBalance(
        department.id,
        left
      )
      console.log('response from replenish', response)
    } catch (e) {
      //@ts-ignore
      throw Error(e)
    }
  }

  const RenderDepartments = (department: Department, someId = 0) => {
    return (
      <AccordionItem key={department.id} uuid={department.id}>
        <AccordionItemHeading className={styles.accordion}>
          <AccordionItemButton className={styles.accordionBtn}>
            <div className={styles.accordionBtn_title}>
              <p className="body-text-3_b">Підрозділ</p>
              &nbsp;
              <p className="body-text-3_i">
                {
                  //@ts-ignore
                  department.sendPost ? 'новий' : department.id
                }
              </p>
              &nbsp;
              <p className="body-text-3_d">
                {!!department.name.length && `| ${department.name}`}{' '}
                {!!department.address.length && `| ${department.address}`}
              </p>
              {/* <p className="body-text-3_i">{someId}</p> */}
            </div>
            <div className={styles.accordionBtn_title}>
              {isEdit && (
                <div
                  className={styles.accordionBtn_title}
                  style={{ cursor: 'pointer' }}
                  //@ts-ignore
                  onClick={() => deleteDepartment(department)}
                >
                  <img src={trashCan} alt="delete" />
                  <p className="body-text-3-b" style={{ paddingRight: 20 }}>
                    Видалити підрозділ
                  </p>
                </div>
              )}
              <AccordionItemState>
                {({ expanded }) => (
                  <img alt="btn" src={expanded ? ArrowDown : ArrowUp} />
                )}
              </AccordionItemState>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <FormField
            isEdit={isEdit}
            error={errors[someId]?.name}
            //@ts-ignore
            formik={formik}
            valueKey={formik.values[someId].name}
            textAreaStyle={{
              resize: isEdit ? 'vertical' : 'none',
            }}
            tag="textarea"
            title="Повна назва підрозділу"
            name={`${someId}.name`}
          />
          <FormField
            isEdit={isEdit}
            error={errors[someId]?.address}
            //@ts-ignore
            formik={formik}
            valueKey={formik.values[someId].address}
            textAreaStyle={{
              resize: isEdit ? 'vertical' : 'none',
            }}
            tag="textarea"
            title="адреса підрозділу (адреса використання програмного продукту)"
            name={`${someId}.address`}
          />
          <div className="info-card__flex-filed-wrapper">
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.latitude}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].latitude}
              tag="input"
              title="широта (latitude)"
              name={`${someId}.latitude`}
            />
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.longitude}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].longitude}
              tag="input"
              title="долгота (longitude)"
              name={`${someId}.longitude`}
            />
            <FormField
              isEdit={isEdit}
              error={errors[someId]?.allowRadius}
              //@ts-ignore
              formik={formik}
              valueKey={formik.values[someId].allowRadius}
              tag="input"
              title="радиус действия, м (allow radius)"
              name={`${someId}.allowRadius`}
              type="number"
            />
          </div>
          <div className="line" />
          {isEdit && (
            <>
              <p className="body-text-3_b">Нарахування по підрозділу</p>
              <div className="info-card__flex-filed-wrapper">
                <FormField
                  isEdit={isEdit}
                  // error
                  formik={formik}
                  valueKey={formik.values[someId].balance.left}
                  tag="input"
                  // type=''
                  title="Нарахувати хвилин"
                  name={`${someId}.balance.left`}
                  type="text"
                />

                <Button
                  btnType="button"
                  disabled={Number(formik.values[someId].balance.left) < 1}
                  type="info"
                  leftIcon={walletIcon}
                  text="&nbsp;Нарахувати"
                  onClick={() => {
                    replenish(
                      //@ts-ignore
                      department,
                      Number(formik.values[someId].balance.left)
                    )
                    dispatch(setEditTabs({ id: 2, value: false }))
                  }}
                />
              </div>
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
    )
  }

  return (
    <div className="shadow-container">
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        //@ts-ignore
        preExpanded={[history.location?.state?.departmentId]}
      >
        <CompanyHeader
          onCancel={resetChanges}
          onEdit={onEdit}
          onSave={formik.submitForm}
        />
        <form
          onSubmit={formik.handleSubmit}
          className={UtilsStyles['form-container']}
          method="post"
        >
          <div className={styles.subHeader}>
            <div className={styles.subHeader__btn}>
              <p className="body-text-3_d">Підрозділів:&nbsp;</p>
              <p className="body-text-3_i">
                {formik.values?.length || 0}&nbsp;
              </p>
              <p className="body-text-3_d">| Ліцензій:&nbsp;</p>
              <p className="body-text-3_i">{licenses?.length || 0}</p>
            </div>
            {isEdit && (
              <div className={styles.subHeader__btn}>
                <p className="body-text-3_i">Додати підрозділ</p>
                &nbsp;
                <button
                  type="button"
                  onClick={() => addNew()}
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: 'black',
                    border: 'none',
                  }}
                />
              </div>
            )}
          </div>
          {formik.values.map(
            //@ts-ignore
            (el: Department, someId = 0) => RenderDepartments(el, someId++)
          )}
        </form>
      </Accordion>
    </div>
  )
}
