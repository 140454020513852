import classNames from 'classnames'
// import { FieldProps, FormikProps } from 'formik'
import { CSSProperties, FC } from 'react'
// import { InitialValues } from './CompanyCard'
import pdfIcon from '../../../assets/img/pdfIcon.svg'
// import { employeeType } from './CompanyLicenceCard'

type CommuneProps = {
  isEdit: boolean
  // formik: FieldProps<InitialValues> | FormikProps<employeeType>
  formik: any
  error?: string
  valueKey: string
  name: string
  title: string
  tagExtraClass?: string[]
  maxLength?: number
}

type InputProps = CommuneProps & {
  type?: string
  tag: 'input'
  onBlur?: () => void
}

type TextareaProps = CommuneProps & {
  tag: 'textarea'
  textAreaStyle: CSSProperties
}

type SelectProps = CommuneProps & {
  type?: string
  tag: 'select'
  options: any[]
}

type DownloadProps = {
  tag: 'download'
  valueKey: string
}

type Props = InputProps | TextareaProps | SelectProps | DownloadProps

export const FormField: FC<Props> = (props) => {
  const { tag } = props

  if (tag === 'input') {
    return <InputField {...props} />
  }
  if (tag === 'select') {
    return <SelectField {...props} />
  }
  if (tag === 'download') {
    return <DownloadLink {...props} />
  }
  return <TextareaField {...props} />
}

const InputField: FC<InputProps> = (props) => {
  const {
    isEdit,
    formik,
    error,
    valueKey,
    name = valueKey,
    type = 'text',
    title,
    tagExtraClass,
    onBlur = () => undefined,
    maxLength = -1,
  } = props

  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': !isEdit,
  })

  const inputLabel = 'input__label-title '
  return (
    <div className="info-card__field-wrapper">
      <label className="info-card__field">
        <p className={inputLabel}>{title}</p>
        <input
          autoComplete="off"
          onBlur={() => onBlur()}
          disabled={!isEdit}
          className={`${inputCx} ${tagExtraClass?.join(' ')} ${
            error && 'input--error'
          }`}
          type={type}
          name={name}
          onChange={formik.handleChange}
          value={valueKey}
          maxLength={maxLength}
        />
        <p className="input__label--error">{error}</p>
      </label>
    </div>
  )
}

const TextareaField: FC<TextareaProps> = (props) => {
  const {
    isEdit,
    formik,
    error,
    valueKey,
    name = valueKey,
    title,
    tagExtraClass,
  } = props

  const rows = Number((valueKey.length * 0.005).toFixed()) || 1

  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': !isEdit,
  })

  const inputLabel = 'input__label-title '

  return (
    <div className="info-card__field-wrapper">
      <label className="info-card__field">
        <p className={inputLabel}>{title}</p>

        <textarea
          disabled={!isEdit}
          style={{
            resize: 'vertical',
          }}
          className={`${inputCx} ${tagExtraClass?.join(' ')} ${
            error && 'input--error'
          }`}
          rows={rows}
          name={name}
          onChange={formik.handleChange}
          value={valueKey}
        />

        <p className="input__label--error">{error}</p>
      </label>
    </div>
  )
}

const SelectField: FC<SelectProps> = (props) => {
  const {
    isEdit,
    formik,
    error,
    valueKey,
    name = valueKey,
    // type = 'text',
    title,
    tagExtraClass,
    options,
  } = props
  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': !isEdit,
  })
  const inputLabel = 'input__label-title '
  return (
    <div className="info-card__field-wrapper">
      <label className="info-card__field">
        <p className={inputLabel}>{title}</p>
        <select
          className={`${inputCx} ${tagExtraClass?.join(' ')} ${
            error && 'input--error'
          }`}
          name={name}
          disabled={!isEdit}
          onChange={formik.handleChange}
          value={valueKey}
          defaultValue={valueKey}
        >
          <option disabled selected value={0}>
            -- select an option --
          </option>
          {options?.map((el: any) => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))}
        </select>
        <p className="input__label--error">{error}</p>
      </label>
    </div>
  )
}

//@ts-ignore
const DownloadLink: FC<DownloadProps> = (props) => {
  const { valueKey } = props
  const inputCx = classNames({
    input: true,
    'body-text-2_b': true,
    'input--disabled': true,
  })
  // console.log('select props', props)
  const inputLabel = 'input__label-title '
  return (
    <div className="info-card__field-wrapper">
      <label className="info-card__field">
        <p className={inputLabel}>Бланк ліцензії</p>
        <a
          href={`https://dev.api.tdl.com.ua/v2/filesystem/license/show/license-${valueKey}.pdf`}
          className={`${inputCx}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            textDecoration: 'none',
            height: 30,
            alignItems: 'center',
          }}
          target="_blank"
          rel="noreferrer"
        >
          <img src={pdfIcon} alt="download" style={{ marginRight: 9 }} />

          <p>Ліцензія.pdf</p>
        </a>
        {/* <p className="input__label--error">{error}</p> */}
      </label>
    </div>
  )
}
