import React, { FC, useRef, useState } from 'react'
import styles from '../styles/FilterField.module.scss'
import SearchIcon from '../../../assets/img/search.svg'
import { useHistory } from 'react-router'
import { routeNames } from '../../../constants/routeNames'

type props = {
  location: any
}

export const FilterField: FC<props> = ({ location }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState('')
  const history = useHistory()

  const findFunc = async () => {
    if (!value.length) history.push({ search: ``, pathname: 'page=1' })
    else history.push({ search: `?search=${value}`, pathname: 'page=1' })
  }
  return (
    <div className={styles.inputWrapper}>
      <img
        className={styles.searchIcon}
        src={SearchIcon}
        alt=""
        onClick={() => findFunc()}
      />
      <input
        ref={inputRef}
        className={styles.input}
        type="text"
        name=""
        id=""
        //@ts-ignore
        placeholder={routeNames[location].findText}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  )
}
