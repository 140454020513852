import React from 'react'
import { Link } from 'react-router-dom'
import { SidebarItemType } from '../types'
import './SidebarItem.scss'
import classNames from 'classnames'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import Arrow from '../../../assets/img/arrow.svg'

type Props = {
  item: SidebarItemType
  selectable: boolean
  minSidebar: boolean
}

export const SidebarItem: React.FC<Props> = ({
  item,
  selectable,
  minSidebar,
}) => {
  const cx = classNames({
    sidebar__item: true,
    'sidebar__item--selectable': selectable,
  })

  return (
    <AccordionItem key={item.id} uuid={item.id}>
      <Link
        to={item.path}
        style={{
          textDecoration: 'none',
        }}
      >
        <AccordionItemHeading>
          <AccordionItemButton>
            <div
              className={cx}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <img
                  className="sidebar__item__img"
                  src={item.img.path}
                  alt={item.img.alt}
                />
                {!minSidebar && (
                  <p className="sidebar__item__title">{item.title}</p>
                )}
              </div>
              <AccordionItemState>
                {({ expanded }) => {
                  return (
                    !minSidebar && (
                      <img
                        alt="btn"
                        src={Arrow}
                        style={{
                          transform: expanded
                            ? 'rotate(270deg)'
                            : 'rotate(90deg)',
                        }}
                      />
                    )
                  )
                }}
              </AccordionItemState>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        {!minSidebar && (
          <AccordionItemPanel style={{ marginLeft: 55 }}>
            <Link
              className="body-text-3_i"
              style={{ textDecoration: 'none', marginBottom: 8 }}
              to={item.path}
            >
              {item.subTitle}
            </Link>
            <br />
            <Link
              className="body-text-3_i"
              style={{ textDecoration: 'none' }}
              //@ts-ignore
              to={item?.createPath}
            >
              {item.createTitle}
            </Link>
          </AccordionItemPanel>
        )}
      </Link>
    </AccordionItem>
  )
}
