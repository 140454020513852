import { useEffect } from 'react'
import { useAppDispatch } from '../../app/hooks'
import { setSelectedCompany } from '../../slice/companySlice'
import { Company } from './components/Company'

export const CreateCompany = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setSelectedCompany({}))
  })
  return (
    <Company
      title="Створити нову компанію"
      requestType="post"
      shouldEdit={true}
    />
  )
}
