import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Table } from '../../components/Table/Table'
import { CompanyService } from '../../services/CompanyService'
import {
  selectCompanyTab,
  selectDeparmentList,
  setSelectedCompany,
} from '../../slice/companySlice'
import { Department } from '../../types/Department'
import { Header } from '../Companies/components/Header'

export const Deparments = () => {
  const departmentList = useAppSelector(selectDeparmentList)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const isChoosing = history.location.pathname.includes('/choose')

  async function pushCompany({
    companyId,
    departmentId,
  }: {
    companyId: string | number
    departmentId: string | number
  }) {
    const loadedCompany = await CompanyService().loadCompanyById(companyId)
    dispatch(setSelectedCompany(loadedCompany))
    if (isChoosing) {
      dispatch(selectCompanyTab(3))
    } else {
      dispatch(selectCompanyTab(2))
    }
    history.push({
      pathname: `/edit-company/${companyId}`,
      state: {
        departmentId: departmentId,
        companyId,
      },
    })
  }

  return (
    <div className="page-container">
      <div className="shadow-container">
        <Header />
        <Table>
          <tbody>
            <tr
              style={{
                textTransform: 'uppercase',
              }}
            >
              <th>Підрозділ</th>
              <th>Адреса</th>
              <th>Компанія</th>
              <th>Ліцензії</th>
            </tr>

            {departmentList?.map((department: Department) => {
              const { id, name, address, employees, company } = department

              return (
                <tr
                  key={id}
                  className="table-row"
                  onClick={() =>
                    pushCompany({
                      companyId: company.id,
                      departmentId: department.id,
                    })
                  }
                >
                  <td className="table-item table-item--bold">{name}</td>
                  <td className="table-item table-item--bold">{address}</td>
                  <td className="table-item">{company?.name}</td>
                  <td className="table-item">{employees?.length}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
