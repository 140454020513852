import React, { FC } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { Button } from '../../../components/Button/Button'
import { NavigationPanel } from '../../../components/NavigationPanel/NavigationPanel'
import {
  selectCompanyTabs,
  selectSelectedCompany,
} from '../../../slice/companySlice'

type Props = {
  onCancel: () => void
  onEdit: () => void
  onSave: () => void
}

export const CompanyHeader: FC<Props> = (props) => {
  const { onCancel, onEdit, onSave } = props
  const selectActiveTab = useAppSelector(selectCompanyTabs)
  const company = useAppSelector(selectSelectedCompany)

  const selectedItem = selectActiveTab.find((tab) => tab.selected)

  return (
    <div
      style={{
        height: 167,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          // padding: '0px 65px',
          paddingLeft: 65,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h1 style={{ marginBottom: 7 }}>{selectedItem?.title}</h1>
          <h3 style={{ marginTop: 0 }}>
            {
              // @ts-ignore
              company?.name
            }
          </h3>
        </div>
        {selectedItem?.isEdit ? (
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                marginRight: 20,
              }}
            >
              <Button type="black" text="Скасувати" onClick={onCancel} />
            </div>
            <Button type="info" text="Зберегти" onClick={onSave} />
          </div>
        ) : (
          <Button text="Редагувати" onClick={onEdit} />
        )}
      </div>

      <NavigationPanel />
    </div>
  )
}
