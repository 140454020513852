import { useHistory, useLocation } from 'react-router-dom'
import { Button } from '../../../components/Button/Button'
import { routeNames } from '../../../constants/routeNames'
import { CountInfo } from './CountInfo'
import { FilterField } from './FilterField'
import { PageBtns } from './PageBtns'

export const Header = () => {
  const history = useHistory()
  const path: string = useLocation().pathname

  const location: string | undefined = Object.keys(routeNames).find(
    (el) => path.includes(el) && el
  )

  const isChoosing = path.includes('/choose')

  return (
    <div
      style={{
        paddingLeft: 65,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>
          {isChoosing
            ? //@ts-ignore
              routeNames[location].chooseTitle
            : //@ts-ignore
              routeNames[location]?.title}
        </h1>

        {!isChoosing && (
          <Button
            //@ts-ignore
            text={routeNames[location].createTitle}
            onClick={() => {
              history.push({
                //@ts-ignore
                pathname: routeNames[location]?.create,
              })
            }}
          />
        )}
      </div>

      <CountInfo />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FilterField location={location} />
        <div />
        <PageBtns />
      </div>
    </div>
  )
}
