// import Lottie from 'react-lottie'
import * as animationData from '../../assets/lottie/loadinglottie.json'

export const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#00000080',
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          display: 'block',
          color: 'white',
          paddingTop: '50vh',
          paddingLeft: '45vw',
          fontSize: 30,
          // left: '50vw',
          // top: '50vh',
        }}
      >
        Завантаження...
      </div>
      {/* <Lottie options={defaultOptions} height={400} width={400} /> */}
    </div>
  )
}
